<template>
  <v-container class="d-flex flex-column pa-8">
    <div class="header-top">
      <h2 class="view-title">New Semantic Report</h2>
    </div>
    <v-card>
      <v-card-text>
        <v-form ref="form" class="form-report">
          <v-text-field
            v-model="report_name"
            label="Report Name"
            outlined
            placeholder="Report Name"
            hint="Report Name"
            persistent-hint
            hide-details="auto"
            :rules="[validators.required]"
          />
          <v-text-field
            v-model="keyword"
            label="Keyword"
            hint="Enter the keyword"
            persistent-hint
            outlined
            placeholder="Keyword"
            hide-details="auto"
            :rules="[validators.required]"
          />
          <v-text-field
            v-for="(url, i) in competitor_urls"
            :key="'dialog' + i"
            v-model="url.value"
            label="Comparsion URL"
            hint="Enter the URL of the competitor"
            persistent-hint
            outlined
            placeholder="Comparsion URL"
            :append-icon="url.remove ? 'mdi-close' : ''"
            @click:append="removeUrl(i)"
            hide-details="auto"
            :rules="[validators.required, validators.urlValidator]"
          />
          <div>
            <v-btn text @click="addUrl" class="btn-more" v-if="competitor_urls.length < 5">
              <v-icon dark left> mdi-plus </v-icon> Add a Competitor URL
            </v-btn>
          </div>

          <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
            <span v-if="loading === false">Create Report</span>
            <span v-else>
              <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
              <span>Sending request...</span>
            </span>
          </v-btn>

          <v-alert v-if="errors" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'
import { required, urlValidator } from '@core/utils/validation'

export default {
  components: {
    mdiClose,
  },
  data() {
    return {
      loading: false,
      loadingLimit: true,

      errors: null,

      form: null,
      data: {},
      use_keyword: false,
      items: [
        { label: 'Phrase', value: 'phrase' },
        { label: 'Exact', value: 'exact' },
        { label: 'Broad', value: 'broad' },
      ],
      report_name: '',
      keyword: '',
      client_url: '',
      competitor_urls: [{ value: '', remove: false }],
      match_type: { label: 'Phrase', value: 'phrase' },

      validators: {
        required,
        urlValidator,
      },
    }
  },
  methods: {
    ...mapActions({
      createReport: 'semantic_analysis/createReport',
      setSnackbar: 'snackbar/set',
    }),
    goToPath(path) {
      this.$router.push({ path })
    },
    addUrl() {
      const index = this.competitor_urls.length
      this.$set(this.competitor_urls, index, { value: '', remove: true })
    },
    removeUrl(index) {
      this.competitor_urls.splice(index, 1)
    },

    submit() {
      if (this.$refs.form.validate()) {
        const urls = this.competitor_urls.map(item => item.value)

        const data = {
          report_name: this.report_name,
          keyword: this.keyword,
          comp_urls: urls,
        }

        this.newReport(data)
      }
    },

    async newReport(data) {
      this.loading = true
      await this.createReport(data)
        .then(resp => {
          if (resp && resp.data) {
            this.setSnackbar({
              status: true,
              text: 'The report has been added to the queue.',
              color: 'primary',
            })

            this.data = resp.data
            if (resp.data.report_id) {
              const path = `/semantic-analysis/await/${resp.data.report_id}`
              this.$router.push({ path })
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
          this.setSnackbar({
            status: true,
            text: 'Failed to create report.',
            color: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}
.btn-submit {
  margin-top: 20px;
}
</style>
